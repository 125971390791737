<script>
import IconColorMixin from '@/mixins/IconColorMixin';

export default defineNuxtComponent({
  mixins: [IconColorMixin],
  props: {
    iconName: {
      type: String,
      default: '',
    },
    size: {
      type: [Number, String],
      default: 24,
    },
    accent: {
      type: String,
      default: 'currentColor',
    },
    darkMode: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getMinSize() {
      return {
        '--minSize': `${this.size}px`,
      };
    },
  },
});
</script>

<template>
  <svg
    :width="size"
    :height="size + 2"
    viewBox="-0.5 0.5 21 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="width-size"
    :style="getMinSize()"
  >
    <path
      d="M0 10C0 4.47715 4.47715 0 10 0V0C15.5228 0 20 4.47715 20 10V10C20 15.5228 15.5228 20 10 20V20C4.47715 20 0 15.5228 0 10V10Z"
      fill="white"
    />
    <path
      d="M10 19C5.02944 19 1 14.9706 1 10H-1C-1 16.0751 3.92487 21 10 21V19ZM19 10C19 14.9706 14.9706 19 10 19V21C16.0751 21 21 16.0751 21 10H19ZM10 1C14.9706 1 19 5.02944 19 10H21C21 3.92487 16.0751 -1 10 -1V1ZM10 -1C3.92487 -1 -1 3.92487 -1 10H1C1 5.02944 5.02944 1 10 1V-1Z"
      fill="#222222"
      fill-opacity="0.16"
    />
  </svg>
</template>

<style scoped>
.width-size {
  min-width: var(--minSize);
}
</style>
